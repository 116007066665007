import * as React from 'react';
import { graphql } from 'gatsby';
import '../../assets/scss/init.scss';
import './blog-post.scss';
import { BlogUtil } from '../../util/blog-util';
import * as moment from 'moment';
import OtherLayout from '../../components/other/other-layout';
import Img from 'gatsby-image';

export default ({ data }) => {
  const post = data.markdownRemark;

  const authors = BlogUtil.getAuthors(post);
  const date = moment(post.frontmatter.date).format('DD/MM/YYYY');
  const authorsBlock = authors.map((author, index) => {
    return <span key={author.id}>
      {index > 0 ? ', ' : ' '}
      <span className="author">
        <span className="author-name">{author.id}</span> (<a href={`https://twitter.com/${author.twitter}`}
                                                             target="_blank"
                                                             rel="noopener noreferrer">@{author.twitter}</a>)
      </span>
    </span>;
  });

  // const tagsBlock = (
  //   <div className="post-single__tags">
  //     <ul className="post-single__tags-list">
  //       {tags &&
  //       tags.map((tag, i) => (
  //         <li className="post-single__tags-list-item" key={tag}>
  //           <Link to={tag} className="post-single__tags-list-item-link">
  //             {post.frontmatter.tags[i]}
  //           </Link>
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // );

  return (
    <OtherLayout className="blog-post-page">
      <article className="blog-post">
        <header>
          <div className="title-img-wrapper">
            {
              post.frontmatter.cover && post.frontmatter.cover.childImageSharp
                ? <Img fluid={post.frontmatter.cover.childImageSharp.fluid}
                       alt={post.frontmatter.title}
                       title={post.frontmatter.title}/>
                : ''
            }
            <h1>{post.frontmatter.title}</h1>
          </div>
          <p className="meta">
            Par : {authorsBlock}.<br/>
            Publié le {date}.<br/>
{/*
            Tags : {post.frontmatter.tags.join(', ')}
*/}
          </p>
          <p className="desc">{post.frontmatter.description}</p>
        </header>
        <div className="container">
          <div className="body" dangerouslySetInnerHTML={{ __html: post.html }}></div>
        </div>
        <footer>
          {/*{tagsBlock}*/}
        </footer>
      </article>
    </OtherLayout>
  )
};


export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
        author {
          id
          bio
          twitter
        }
        authors {
          id
          bio
          twitter
        }
        category
        description
        cover {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
